import request from '../utils/request'

// API-F13 - [CreditCardController] creditCard/create 信用卡新增&編輯
export function addCreditCard(data) {
  return request({
    url: `/creditCard/create`,
    method: 'post',
    data
  })
}

// API-F14 - [CreditCardController] creditCard/detail 信用卡詳細
export function getCreditCard(params = {}) {
  return request({
    url: `/creditCard/detail`,
    method: 'get',
    params
  })
}
